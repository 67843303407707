import {
  collection,
  query,
  where,
  orderBy,
  addDoc,
  doc,
  updateDoc,
  Timestamp,
  getDocs,
  limit,
} from "firebase/firestore";
import {
  SubmittedDocument,
  SubmittedDocuments,
} from "~/types/SubmittedDocument";
import { DocumentState } from "~/types/enums/DocumentState";

import { useCollection } from "vuefire";
import { DocumentSubmissionType } from "~/types/enums/DocumentSubmissionType.enum";

export const useDocuments = () => {
  const streamPendingUserDocuments = () => {
    const uid = useCurrentUID();

    if (!uid) {
      throw new Error("Unauthorized");
    }
    const db = useFirestore();

    const documentCollectionRef = collection(db, "/documents");
    const documentCollectionQuery = query(
      documentCollectionRef,
      where("userId", "==", uid),
      where("state", "in", [
        DocumentState.pending,
        DocumentState.notImported,
        DocumentState.conversionFailed,
        DocumentState.converting,
        DocumentState.evaluating,
      ]),
      orderBy("lastUpdatedTimestamp", "desc")
    );

    return useCollection<SubmittedDocument>(documentCollectionQuery);
  };

  const streamUngradedUserDocuments = () => {
    const uid = useCurrentUID();

    if (!uid) {
      throw new Error("Unauthorized");
    }
    const db = useFirestore();
    const documentCollectionRef = collection(db, "/documents");
    const documentCollectionQuery = query(
      documentCollectionRef,
      where("userId", "==", uid),
      where("state", "not-in", [DocumentState.graded, DocumentState.archived])
    );

    return useCollection<SubmittedDocument>(documentCollectionQuery);
  };

  const streamGradedUserDocuments = () => {
    const uid = useCurrentUID();

    if (!uid) {
      throw new Error("Unauthorized");
    }
    const db = useFirestore();
    const documentCollectionRef = collection(db, "/documents");
    const documentCollectionQuery = query(
      documentCollectionRef,
      where("userId", "==", uid),
      where("state", "==", DocumentState.graded),
      orderBy("lastUpdatedTimestamp", "desc")
    );

    return useCollection<SubmittedDocument>(documentCollectionQuery);
  };

  const createDocument = async (
    name: string,
    params?: {
      userId: string;
      classroom?: {
        id: string;
        name: string;
      };
      assignment?: {
        id: string;
        name: string;
      };
      student?: {
        id: string;
        name: string;
      };
      checklist?: {
        id: string;
        path: string;
      };
      allowFinalDraftGradedState?: boolean;
      documentSubmissionType?: DocumentSubmissionType;
      googleDriveFileId?: string;
    }
  ) => {
    // Ok so once a file is uploaded we want to first create a document
    const timestamp = new Date().getTime();
    const document: SubmittedDocument = {
      name: name,
      state: DocumentState.uploading,
      createOnTimestamp: timestamp,
      lastUpdatedTimestamp: timestamp,
      userId: params?.userId,
      isLate: false,
      submittedAtTimestamp: Timestamp.now(),
      doesNOTExpectAssignment: false,
      isSample: false,
      documentSubmissionType: params?.documentSubmissionType,
      allowFinalDraftGradedState: params?.allowFinalDraftGradedState,
      googleDriveFileId: params?.googleDriveFileId,
    } as SubmittedDocument;

    if (params?.classroom) {
      document.classroomId = params.classroom.id;
      document.classroomName = params.classroom.name;
    }

    if (params?.assignment) {
      document.assignmentId = params.assignment.id;
      document.assignmentName = params.assignment.name;
    }

    if (params?.student) {
      document.studentId = params.student.id;
      document.studentName = params.student.name;
    }

    if (
      params?.checklist &&
      document.documentSubmissionType != DocumentSubmissionType.keyWordOutline
    ) {
      document.checklistId = params.checklist.id;
      document.checklistPath = params.checklist.path;
    }

    const db = useFirestore();
    const documentRef = collection(db, "/documents");
    const response = await addDoc(documentRef, document);
    return response.id;
  };

  const deleteDocument = async (documentId: string) => {
    const db = useFirestore();
    const docRef = doc(db, `/documents/${documentId}`);
    updateDoc(docRef, {
      state: DocumentState.archived,
    });
  };

  const getDocumentsWithChecklistId = async (
    checklistId: string
  ): Promise<SubmittedDocuments> => {
    const db = useFirestore();
    const documentCollectionRef = collection(db, "/documents");
    const documentCollectionQuery = query(
      documentCollectionRef,
      where("checklistId", "==", checklistId)
    );

    const documents = await getDocs(documentCollectionQuery);

    return documents.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as SubmittedDocument;
    });
  };

  const getLatestDocumentRevisionId = async (
    documentId: string
  ): Promise<string | undefined> => {
    const db = useFirestore();
    const revisionsRef = collection(db, `/documents/${documentId}/revisions`);
    const revisionsQuery = query(
      revisionsRef,
      orderBy("lastUpdatedTimestamp", "desc"),
      limit(1)
    );
    const revisionsSnapshot = await getDocs(revisionsQuery);

    if (revisionsSnapshot.docs.length == 0) return undefined;

    const revisionId = revisionsSnapshot.docs[0].id;
    return revisionId;
  };

  const updateDocument = async (document: SubmittedDocument) => {
    if (document.id == undefined) {
      throw new Error("Document id is undefined");
    }

    const db = useFirestore();
    const docRef = doc(db, `/documents/${document.id}`);
    updateDoc(docRef, {
      ...document,
    });
  };

  const updateDocumentStateById = async (
    documentId: string,
    state: DocumentState
  ) => {
    const db = useFirestore();
    const docRef = doc(db, `/documents/${documentId}`);
    await updateDoc(docRef, {
      state: state,
    });
  };

  return {
    streamUngradedUserDocuments,
    streamPendingUserDocuments,
    streamGradedUserDocuments,
    createDocument,
    deleteDocument,
    getDocumentsWithChecklistId,
    getLatestDocumentRevisionId,
    updateDocument,
    updateDocumentStateById,
  };
};
